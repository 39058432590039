//Casos
.ticket {
  width: 100%;;
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
}

.ticket-header {
  background-color: #33a2e22c;
  color: #fff;
  height: 150px;
  border: 2px solid #134461;
  border-bottom: 2px dotted #fcfcfcce;
  border-radius: 10px 10px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Alinea el texto al centro horizontalmente */
  padding: 1px;
}

.ticket-header h4 {
  margin: 0; /* Elimina el margen predeterminado del encabezado h4 */

}


.ticket-body {
  background-color: #ffffff;
  // max-height: 1000px; /* Establece la altura máxima */
  border: 2px solid #134461;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;

}

.dropdown-container {
  position: absolute;
  left: 10px; /* Ajusta este valor según sea necesario */
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.timeLine {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

}
 /* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
 .timeLine::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.308); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.timeLine::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

.button-plus {
  position: absolute; /* Añade esta línea para posicionar el ícono correctamente */
  right: 20px; /* Ajusta la posición del ícono desde la derecha según tu preferencia */
  top: 20px; /* Ajusta la posición del ícono desde la parte superior según tu preferencia */
  display: flex;
}



.tooltip-container {
  --background-light: #c7b39d;
  --background-dark: #ffa32be7; 
  --text-color-light: #ffffff;
  --text-color-dark: #ffffff;
  --bubble-size: 12px;
  --glow-color: rgba(255, 255, 255, 0.5); /* Brighter glow color */

  background: var(--background-light);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 25px;
  padding: 0em 0.4em 0.3em 0.4em;
  color: var(--text-color-light);
  border-radius: 20px; /* Rounded edges */
  display: inline-block;
  position: relative;
}
/* Style for the bubble tooltip */
.tooltip {
  position: absolute;
  bottom: calc(100% + 5px); /* Mueve el tooltip arriba del botón y agrega un poco de espacio */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.6em 1em;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: var(--bubble-size);
  background: #0a8a05bd ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Triangle arrow for the bubble tooltip */
.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  border-style: solid;
  border-width: 8px 8px 0;
  border-color: #0a8a05bd  transparent transparent;
}

/* Tooltip appearance on hover */
.tooltip-container:hover {
  background: var(--background-dark);
  color: var(--text-color-dark);
  box-shadow: 0 0 20px var(--glow-color); /* Brighter glow effect */
}

.tooltip-container:hover .tooltip {
  top: -140%; /* Adjusted position for the bubble tooltip */
  opacity: 1;
  bottom: calc(100% + 10px); /* Mueve el tooltip arriba del botón y agrega un poco de espacio */
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.custom-color {
  background-color: #0a8a05bd;
}

.card-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 0fr);
  gap: 10px;
}

.card-rutero {
  width: 268px;
  max-width: 268px;
  height: 204px;
  background: #f5f5f5;
  padding: 2rem 1.5rem;
  transition: box-shadow .3s ease, transform .2s ease;
  position: relative;
  cursor: pointer;
 }

  /*Text*/
  .card-rutero-title {
    color: #333;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 15px;
   }

   .card-rutero-subtitle {
    color: #859ba8;
    font-size: 0.8em;
   }
   
   /*Hover*/
   .card-rutero:hover {
    box-shadow: 0 8px 50px #23232333;
   }
   
   .card-rutero:hover .card-rutero-info {
    transform: translateY(-5%);
   }

   .fixed-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 2px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-top: 2px solid #e9e9e9;
    width: 100%;
    z-index: 100; /* Asegura que este contenedor tenga un z-index alto */
  }

  .content_cart_ {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 2rem 2rem 1rem 2rem;
    display: grid;
    grid-template-rows: 1fr auto;
    width: 300px;
  }
  